<template>
  <v-app theme="dark">
    <!-- Navbar -->
    <v-app-bar class="px-3">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $t('general.title') }}</v-toolbar-title>
      <template v-if="$vuetify.display.smAndUp">
        <v-spacer></v-spacer>
        <v-btn to="/">{{ $t('menu.dashboard') }}</v-btn>
        <v-btn to="/purchases">{{ $t('menu.purchases') }}</v-btn>
        <v-btn to="/campaign-views">{{ $t('menu.campaign_stat') }}</v-btn>
        <v-btn to="/shareholders">{{ $t('menu.shareholders') }}</v-btn>
      </template>

    </v-app-bar>

    <!-- Drawer -->
    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item link :to="{ name: 'dashboard' }">
          <v-list-item-title>{{ $t('menu.dashboard') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'purchases' }">
          <v-list-item-title>{{ $t('menu.purchases') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'campaign-views' }">
          <v-list-item-title>{{ $t('menu.campaign_stat') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'shareholders' }">
          <v-list-item-title>{{ $t('menu.shareholders') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Page Content -->
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
  }),
}
</script>
