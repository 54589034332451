<template>
  <v-app theme="dark">
    <!-- Page Content -->
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
export default {
}
</script>
