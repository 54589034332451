import {createRouter, createWebHistory} from 'vue-router'
import store from "@/plugins/vuex";

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import("@/pages/DashboardPage.vue"),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("@/pages/LoginPage.vue"),
  },
  {path: '/purchases', name: 'purchases', component: () => import("@/pages/PurchaseList.vue")},
  {path: '/campaign-views', name: 'campaign-views', component: () => import("@/pages/CampaignList.vue")},
  {path: '/shareholders', name: 'shareholders', component: () => import("@/pages/ShareholderList.vue")},
]

// Create router instance
const router = createRouter({
  history: createWebHistory('/'),
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication and the user is not logged in
  if (to.name !== 'login' && (!store.getters.accessToken || !store.getters.user)) {
    // Redirect to the login page
    // Check authentication in the next event loop to allow the loading page to render
    next({name: 'login'});
  } else if (to.name === 'login' && (store.getters.accessToken && store.getters.user)) {
    next({name: 'dashboard'})
  } else {
    // Continue to the route
    next();
  }

});


export default router
