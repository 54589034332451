// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    errorMessage: null,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    accessToken: localStorage.getItem('accessToken') || null,
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
      localStorage.setItem('accessToken', token);
    },
    clearAccessToken(state) {
      state.accessToken = null;
      localStorage.removeItem('accessToken');
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
    clearErrorMessage(state) {
      state.errorMessage = null;
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    clearUser(state) {
      state.user = null;
      localStorage.removeItem('user');
    }
  },
  actions: {
    showError({ commit }, message) {
      commit('setErrorMessage', message);
    },
    clearError({ commit }) {
      commit('clearErrorMessage');
    },
    logout({ commit }) {
      commit('clearUser', null);
      commit('clearAccessToken');
    },
    login({ commit }, { user, token }){
      commit('setUser', user);
      commit('setAccessToken', token);
    }
  },
  getters: {
    errorMessage: (state) => state.errorMessage,
    user: (state) => state.user,
    accessToken: (state) => state.accessToken,
    baseUrl: (state) => state.baseUrl,
  }
});
