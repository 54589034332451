import {en, fa} from "vuetify/locale";
import {createI18n} from "vue-i18n";
import faJson from "@/locales/fa.json";

const messages = {
  en: {
    $vuetify: {
      ...en,
      dataIterator: {
        rowsPerPageText: 'Items per page:',
        pageText: '{0}-{1} of {2}',
      },
    },
  },
  fa: {
    ...faJson,
    $vuetify: {
      ...fa,
      dataIterator: {
        loadingText: 'در حال بارگذاری...',
        rowsPerPageText: 'Element per sida:',
        pageText: '{0}-{1} av {2}',
      },
    },
  },
};

const i18n = createI18n({
  legacy: false,
  locale: 'fa',
  fallbackLocale: 'fa',
  messages: messages
})

export default i18n
