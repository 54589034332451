import { createApp } from 'vue'
import './assets/styles/main.css';

import App from './App.vue'

import router from "@/plugins/router";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import axios from "@/plugins/axios";
import store from "@/plugins/vuex";
import './registerServiceWorker'

const app = createApp(App)

app.use(vuetify)
app.use(router)
app.use(i18n)
app.use(store)
app.config.globalProperties.$api = axios

app.mount('#app')
