import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {useI18n} from 'vue-i18n'
import {createVueI18nAdapter} from 'vuetify/locale/adapters/vue-i18n'
import i18n from "@/plugins/i18n";

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    adapter: createVueI18nAdapter({i18n, useI18n}),
  },
})

export default vuetify
