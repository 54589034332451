<template>
  <div>
    <notify-snackbar ref="snackbar" />
    <component :is="layout" />
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import BlankLayout from "@/layouts/BlankLayout.vue";
import NotifySnackbar from "@/components/NotifySnackbar.vue";

export default {
  name: 'App',
  components: {
    NotifySnackbar
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    layout() {
      if(!this.$route.name || this.$route.name === 'login') {
        return BlankLayout
      }
      return MainLayout
    }
  },
  mounted() {
    this.$nextTick(function () {
      document.getElementById('preloader').style.display = 'none';
    })
  }
}
</script>

<style>
</style>
