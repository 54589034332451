import axios from 'axios';
import store from './vuex';
import i18n from './i18n';

const client = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  // You can add your other custom settings here
});

// Add a request interceptor
client.interceptors.request.use((config) => {
  // Do something before request is sent
  // For example, add authorization header
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
}, (error) => {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
client.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response?.status === 401) {
      store.dispatch('showError', i18n.global.t('error.unauthorized'));
      if(store.getters.user || store.getters.accessToken) store.dispatch('logout');
    } else if (error.response?.status === 403) {
      store.dispatch('showError', i18n.global.t('error.forbidden'));
    } else if (error.response?.status === 404) {
      store.dispatch('showError', i18n.global.t('error.not_found'));
    } else if (error.response?.status === 500) {
      store.dispatch('showError', i18n.global.t('error.server'));
    } else if (error.response?.status === 422) {
      store.dispatch('showError', i18n.global.t('error.bad_request'));
    } else {
      const message = error.response?.data?.message || i18n.global.t('error.unknown');
      store.dispatch('showError', message);
    }
    return Promise.reject(error);
  }
)
export default client;
