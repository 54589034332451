<template>
  <v-snackbar v-model="showSnackbar" :timeout="6000" top right>
    {{ errorMessage }}
    <template v-slot:actions>
      <v-btn color="red" @click="showSnackbar = false;">{{ $t('general.close') }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      showSnackbar: false,
    };
  },
  computed: {
    ...mapGetters(['errorMessage']),
  },
  watch: {
    errorMessage(newValue) {
      if (newValue) {
        this.showSnackbar = true;
      }
    },
    showSnackbar(newValue) {
      if(!newValue) {
        this.clearError();
      }
    }
  },
  methods: {
    ...mapActions(['clearError']),

  },
};
</script>

<style scoped>
.v-snackbar {
  z-index: 2000;
}
</style>
